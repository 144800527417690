<template>
  <huboo-page>
    <huboo-page-heading :heading="$t('audit.audit') | title" />
    <huboo-table
      id="auditTable"
      @update:options="handleUpdateOptions"
      hide-date-picker
      hide-search
      :headers="headers"
      :items="items"
      v-bind="options"
      :server-items-length="serverItemsLength"
      :loading="isLoading"
    >
      <template #filter>
        <huboo-filters
          v-if="meta.filters"
          @filtersClear="onFiltersClear"
          @filtersApply="onApplyFilters"
          :activeFilters="activeFilters"
        >
          <v-row class="filter-row">
            <v-col cols="3" sm="6" md="4" class="date-picker">
              <huboo-date-range-picker @update="updateDateRange" modal-width="300px" />
            </v-col>
            <v-col cols="3" sm="6" md="4">
              <v-text-field
                dense
                outlined
                :label="$t('audit.hubooBox')"
                v-model="availableFilters.hubooBoxFilter"
              />
            </v-col>
            <v-col cols="3" sm="6" md="4">
              <v-select
                :label="$t('audit.auditable_type')"
                :items="availableAuditableType || []"
                v-model="availableFilters.auditableTypeFilter"
                dense
                outlined
              />
            </v-col>
            <v-col cols="3" sm="6" md="4">
              <v-select
                :label="$t('audit.event')"
                :items="availableEvents || []"
                v-model="availableFilters.eventFilter"
                dense
                outlined
              />
            </v-col>
          </v-row>
        </huboo-filters>
      </template>
    </huboo-table>
  </huboo-page>
</template>

<script>
import i18n from '@/boot/i18n'
import { title } from '@/utilities/filters'
import ClientMixin from '@/mixins/client'
import filtersMixin from '@/mixins/filters'
import qs from 'qs'

export default {
  name: 'Audit',
  mixins: [ClientMixin, filtersMixin],
  data: () => ({
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    // filters
    activeFilters: 0,
    availableFilters: {
      auditableTypeFilter: '',
      hubooBoxFilter: '',
      eventFilter: '',
    },
    search: '',
    selected: null,
  }),
  computed: {
    availableAuditableType() {
      return this.meta.filters.auditableType
    },

    availableEvents() {
      return this.meta.filters.event
    },
    headers() {
      const list = [
        { text: title(i18n.t('audit.modifiedBy')), sortable: false, value: 'modified_by' },
        { text: title(i18n.t('audit.event')), sortable: false, value: 'event' },
        { text: title(i18n.t('audit.auditable_type')), sortable: false, value: 'auditable_type' },
        { text: title(i18n.t('audit.auditable_id')), sortable: false, value: 'auditable_id' },
        { text: title(i18n.t('audit.oldFieldValue')), sortable: false, value: 'old_values' },
        { text: title(i18n.t('audit.newFieldValue')), sortable: false, value: 'new_values' },
        { text: title(i18n.t('common.updatedAt')), sortable: false, value: 'updated_at' },
      ]

      if (this.isAdmin) {
        list.splice(1, 0, {
          text: title(this.$t('audit.hubooBox')),
          sortable: false,
          value: 'huboo_box',
        })
      }
      return list
    },
    audits() {
      return this.$store.getters['billing/getAudits']
    },
    isLoading() {
      return (
        this.loading ||
        this.$store.getters['core/apiEndpointIsLoading']({
          method: 'GET',
          url: this.urlWithFilterQuery,
        })
      )
    },
    items() {
      return this.audits.data.map(audit => ({
        ...audit,
        updated_at: this.formatDate(audit.updated_at),
        old_values: JSON.stringify(audit.old_values),
        new_values: JSON.stringify(audit.new_values),
        modified_by: audit.user?.email || 'unknown',
      }))
    },
    meta() {
      return this.audits.meta || { total: this.items.length }
    },
    url() {
      return '/huboo-box/' + this.box?.hubooBox + '/audits'
    },
    urlWithFilterQuery() {
      return this.url + '?' + this.buildFiltersQuery
    },
    buildFiltersQuery() {
      const filterObject = {
        filters: {
          ...(this.availableFilters.auditableTypeFilter
            ? { auditableType: this.availableFilters.auditableTypeFilter }
            : null),
          ...(this.availableFilters.eventFilter
            ? { event: this.availableFilters.eventFilter }
            : null),
          ...(this.availableFilters.hubooBoxFilter
            ? { hubooBox: this.availableFilters.hubooBoxFilter }
            : null),
          ...(this.dateRange ? { date: this.dateRange?.join(',') } : null),
        },
      }

      return qs.stringify(filterObject, { encode: false })
    },
  },
  watch: {
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onFiltersClear() {
      this.clearFilters(this.availableFilters)
      this.activeFilters = 0
    },
    onApplyFilters() {
      this.applyFilters(this.availableFilters)
    },
    fetch() {
      if (!this.loading) {
        const { options, dateRange, availableFilters } = this
        const { page } = options
        let params = {}
        params.itemsPerPage = this.options.itemsPerPage
        if (page) params.page = page
        if (
          availableFilters.auditableTypeFilter ||
          availableFilters.eventFilter ||
          availableFilters.hubooBoxFilter ||
          dateRange
        ) {
          this.$store.dispatch('billing/fetchAudits', { params, url: this.urlWithFilterQuery })
        } else {
          this.$store.dispatch('billing/fetchAudits', { params, url: this.url })
        }
      }
    },
  },
}
</script>
