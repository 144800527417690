const filtersMixin = {
  computed: {},
  methods: {
    applyFilters(object) {
      this.fetch()
      const asArray = Object.values(object)
      this.activeFilters = asArray.filter(item => item !== '').length

      this.options.page = 1
    },

    clearFilters(object) {
      Object.keys(object).forEach(function(f) {
        object[f] = ''
      })
      this.options.page === 1 ? this.fetch() : (this.options.page = 1)
    },
  },
}

export default filtersMixin
